import { Injectable } from '@angular/core';
import { ForgotPassword, ConfirmPassword, User } from 'app/shared/models/user.model';
import { SignupUser } from 'app/shared/models/user.model';
import axios from 'axios';
import {URLS} from "./urls";



@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor() {}

  async forgotPassword(userDetails) {
    return await axios.post<ForgotPassword>(
      URLS.forget_password,
      userDetails
    );
  }

  async signupUser(userDetails) {
    return await axios.post<SignupUser>(URLS.signup_user, userDetails);
  }

  async registerUser(uriValues, formValues) {
    return await axios.post<User>(
      URLS.activate_account +
      uriValues.uidb64 +
        '/' +
        uriValues.token +
        '/',
      { "first_name": formValues.first_name, "last_name": formValues.last_name, "phone": formValues.phone,"password": formValues.password, "email_updates": formValues.email_updates }
    );
  }
  
  async confirmPassword(passwordDetails) {
    return await axios.post<ConfirmPassword>(
      URLS.forgot_reset_password +
        passwordDetails.uidb64 +
        '/' +
        passwordDetails.token +
        '/',
      { password: passwordDetails.password }
    );
  }

  async activeRouter(RouteDetails) {
    return await axios.post(
      URLS.activate_account +
        RouteDetails.uidb64 +
        '/' +
        RouteDetails.token +
        '/'
    );
  }
}
