import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-av-full-card-summary-item',
  templateUrl: './av-full-card-summary-item.component.html',
  styleUrls: ['./av-full-card-summary-item.component.scss']
})
export class AvFullCardSummaryItemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
