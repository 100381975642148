import { ScheduleDataCommon } from './dayschedulecommon.model';
import { ILabel, Label } from './label.model';
import { IUser, User } from './user.model';
import * as momentTZ from 'moment-timezone'
export class Location {
  id: number;
  name: string;
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  state?: string;
  zip_code?: string;
  country?: string;
  privacy?: number;
  allow_911?: boolean;
  summary? = {
    aeds: 0,
    battery: {
      good: 0,
      low: 0,
      unknown: 0,
    },
    pad_cartridges: {
      good: 0,
      replace: 0,
      upcoming: 0,
      're-install': 0,
      unknown: 0,
    },
    other_issues: {
      critical_test: 0,
      training: 0,
      software: 0,
      temp: 0,
      other: 0,
    },
    sync_status: {
      synced: 0,
      unsynced: 0,
    },
  };

  owner?:number;
  users?: User[] = [];
  labels?: Label[] = [];
  schedule?: any;

  constructor(location) {
    if (typeof location == 'object') {
      this.id = location.id;
      this.name = location.name;
      this.address_line_1 = location.address_line_1;
      this.address_line_2 = location.address_line_2;
      this.city = location.city;
      this.state = location.state;
      this.zip_code = location.zip_code;
      this.country = location.country;
      this.privacy = location.privacy;
      this.allow_911 = location.allow_911;

      if (location.owner !== undefined && location.owner !== null) {
        this.owner = location.owner;
      }

      if (location.users !== undefined && location.users !== null) {
        for (let each of location.users) {
          this.users.push(new User(each as IUser));
        }
      }
      if (location.labels !== undefined && location.labels !== null) {
        for (let each of location.labels) {
          this.labels.push(new Label(each as ILabel));
        }
      }

     // TODO: uncomment it once the schedule format is fixed in API
      if (location.schedule !== undefined && location.schedule !== null) {
          this.schedule = new Schedule(location.schedule)
      }


      if (typeof location.summary == 'object') {
        this.summary = {
          ...this.summary,
          ...location.summary,
        };
      }
    } else {
      this.name = '';
      this.address_line_1 = '';
      this.address_line_2 = '';
      this.city = '';
      this.state = '';
      this.zip_code = '';
      this.country = '';
      this.privacy = 0;
      this.allow_911 = false;
      this.labels = [];
    }
  }
}

export class DaySchedule {
  dayEncoding = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday',
  };
  start: number;
  end: number;
  day: number;
  dayText: string;
  availability = true;
  constructor(day: number, start: number, end: number) {
    if (day !== undefined && start !== undefined && end !== undefined) {
      this.day = day;
      this.dayText = this.dayEncoding[day];
      this.start = start;
      this.end = end;
      if (this.start === 0 && this.end === 0) {
        this.availability = false;
      }
    }
  }
  getScheduleEncodeValue() {
    const dayValue = this.day
    let x = {
      [dayValue] : [this.start, this.end],
    };
    return x
  }
  getEncodeValue() {
    let dayValue = this.day;
    return {
      dayValue: [this.start, this.end],
    };
  }
}


export class Schedule {
  monday: DaySchedule;
  tuesday: DaySchedule;
  wednesday: DaySchedule;
  thursday: DaySchedule;
  friday: DaySchedule;
  saturday: DaySchedule;
  sunday: DaySchedule;

  constructor(schedule) {
    if ((schedule !== undefined) && (Object.keys(schedule).length !== 0)) {
      if(schedule['1']){this.monday = new DaySchedule(1, schedule['1'][0], schedule['1'][1]);}
      if(schedule['2']){this.tuesday = new DaySchedule(2, schedule['2'][0], schedule['2'][1]);}
      if(schedule['3']){this.wednesday = new DaySchedule(3, schedule['3'][0], schedule['3'][1]);}
      if(schedule['4']){this.thursday = new DaySchedule(4, schedule['4'][0], schedule['4'][1]);}
      if(schedule['5']){this.friday = new DaySchedule(5, schedule['5'][0], schedule['5'][1]);}
      if(schedule['6']){this.saturday = new DaySchedule(6, schedule['6'][0], schedule['6'][1]);}
      if(schedule['7']){this.sunday = new DaySchedule(7, schedule['7'][0], schedule['7'][1]);}
    }
  }

  getEncodeValue() {
    return {
      ...this.monday.getEncodeValue(),
      ...this.tuesday.getEncodeValue(),
      ...this.wednesday.getEncodeValue(),
      ...this.thursday.getEncodeValue(),
      ...this.friday.getEncodeValue(),
      ...this.saturday.getEncodeValue(),
      ...this.sunday.getEncodeValue(),
    };
  }
}

export class DayScheduleData {
  dayEncoding = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday',
  };
  start: number;
  end: number;
  day: number;
  dayText: string;
  availability = true;
  constructor(day: number, start: number, end: number) {
    if (day !== undefined && start !== undefined && end !== undefined) {
      this.day = day;
      this.dayText = this.dayEncoding[day];
      this.start = start;
      this.end = end;
      if (this.start === 0 && this.end === 0) {
        this.availability = false;
      }
    }
  }
  getEncodeValue() {
    // let dayValue = this.day;
    const dayValue = this.day
   let x = {
  [dayValue] : [this.start, this.end],
    };
    return x
  }
}
export class ScheduleData {
  monday: DayScheduleData;
  tuesday: DayScheduleData;
  wednesday: DayScheduleData;
  thursday: DayScheduleData;
  friday: DayScheduleData;
  saturday: DayScheduleData;
  sunday: DayScheduleData;
  nullValue: Number;

  constructor(schedule, timezone?:string) {
    if ((schedule !== undefined) && (Object.keys(schedule).length !== 0)) {
      let monday: number = schedule.findIndex((item) => item.frequency === 1);
      let tuesday: number = schedule.findIndex((item) => item.frequency === 2);
      let wednesday: number = schedule.findIndex((item) => item.frequency === 3);
      let thursday: number = schedule.findIndex((item) => item.frequency === 4);
      let friday: number = schedule.findIndex((item) => item.frequency === 5);
      let saturday: number = schedule.findIndex((item) => item.frequency === 6);
      let sunday: number = schedule.findIndex((item) => item.frequency === 7);
      let weekDay: number = schedule.findIndex((item) => item.frequency === 8);
      let weekEnd: number = schedule.findIndex((item) => item.frequency === 9);
      let allDay: number = schedule.findIndex((item) => item.frequency === 10);
      this.nullValue = schedule.findIndex((item) => item.frequency === '');
     if(monday !== -1){
        this.monday = new DayScheduleData(schedule[monday].frequency,timezone?this.convertToZone(timezone, schedule[monday].hoursfrom):schedule[monday].hoursfrom, timezone?this.convertToZone(timezone, schedule[monday].hoursto):schedule[monday].hoursto);
      } else {
         if( allDay !== -1){
          this.monday = new DayScheduleData(1, timezone?this.convertToZone(timezone, schedule[allDay].hoursfrom):schedule[allDay].hoursfrom, timezone?this.convertToZone(timezone, schedule[allDay].hoursto):schedule[allDay].hoursto);
          }
         else if(weekDay !== -1){
            this.monday = new DayScheduleData(1, timezone?this.convertToZone(timezone, schedule[weekDay].hoursfrom):schedule[weekDay].hoursfrom, timezone?this.convertToZone(timezone, schedule[weekDay].hoursto):schedule[weekDay].hoursto);
          }
          else {
            this.monday = new DayScheduleData(1, 0, 0);
          }
      }
      if(tuesday !== -1){
        this.tuesday = new DayScheduleData(schedule[tuesday].frequency, timezone?this.convertToZone(timezone, schedule[tuesday].hoursfrom):schedule[tuesday].hoursfrom, timezone?this.convertToZone(timezone, schedule[tuesday].hoursto):schedule[tuesday].hoursto);
      } else {

        if( allDay !== -1){
        this.tuesday = new DayScheduleData(2, timezone?this.convertToZone(timezone, schedule[allDay].hoursfrom):schedule[allDay].hoursfrom, timezone?this.convertToZone(timezone, schedule[allDay].hoursto):schedule[allDay].hoursto);
        }
        else if(weekDay !== -1){
          this.tuesday = new DayScheduleData(2, timezone?this.convertToZone(timezone, schedule[weekDay].hoursfrom):schedule[weekDay].hoursfrom, timezone?this.convertToZone(timezone, schedule[weekDay].hoursto):schedule[weekDay].hoursto);
        }
        else {
          this.tuesday = new DayScheduleData(2, 0, 0);
        }
      }
      if(wednesday !== -1){
        this.wednesday = new DayScheduleData(schedule[wednesday].frequency, timezone?this.convertToZone(timezone, schedule[wednesday].hoursfrom):schedule[wednesday].hoursfrom, timezone?this.convertToZone(timezone, schedule[wednesday].hoursto):schedule[wednesday].hoursto);
       } else {

        if( allDay !== -1){
          this.wednesday = new DayScheduleData(3, timezone?this.convertToZone(timezone, schedule[allDay].hoursfrom):schedule[allDay].hoursfrom, timezone?this.convertToZone(timezone, schedule[allDay].hoursto):schedule[allDay].hoursto);
        }
        else if(weekDay !== -1){
          this.wednesday = new DayScheduleData(3, timezone?this.convertToZone(timezone, schedule[weekDay].hoursfrom):schedule[weekDay].hoursfrom, timezone?this.convertToZone(timezone, schedule[weekDay].hoursto):schedule[weekDay].hoursto);
        }
        else {
          this.wednesday = new DayScheduleData(3, 0, 0);
        }
      }
      if(thursday !== -1){
        this.thursday = new DayScheduleData(schedule[thursday].frequency, timezone?this.convertToZone(timezone, schedule[thursday].hoursfrom):schedule[thursday].hoursfrom, timezone?this.convertToZone(timezone, schedule[thursday].hoursto):schedule[thursday].hoursto);
        } else {

        if( allDay !== -1){
          this.thursday = new DayScheduleData(4, timezone?this.convertToZone(timezone, schedule[allDay].hoursfrom):schedule[allDay].hoursfrom, timezone?this.convertToZone(timezone, schedule[allDay].hoursto):schedule[allDay].hoursto);
        }
        else if(weekDay !== -1){
          this.thursday = new DayScheduleData(4, timezone?this.convertToZone(timezone, schedule[weekDay].hoursfrom):schedule[weekDay].hoursfrom, timezone?this.convertToZone(timezone, schedule[weekDay].hoursto):schedule[weekDay].hoursto);
        }
        else {
          this.thursday = new DayScheduleData(4, 0, 0);
        }
      }
      if(friday !== -1){
        this.friday = new DayScheduleData(schedule[friday].frequency, timezone?this.convertToZone(timezone, schedule[friday].hoursfrom):schedule[friday].hoursfrom, timezone?this.convertToZone(timezone, schedule[friday].hoursto):schedule[friday].hoursto);
       } else {
        if( allDay !== -1){
          this.friday = new DayScheduleData(5, timezone?this.convertToZone(timezone, schedule[allDay].hoursfrom):schedule[allDay].hoursfrom, timezone?this.convertToZone(timezone, schedule[allDay].hoursto):schedule[allDay].hoursto);
        }
        else if(weekDay !== -1){
          this.friday = new DayScheduleData(5, timezone?this.convertToZone(timezone, schedule[weekDay].hoursfrom):schedule[weekDay].hoursfrom, timezone?this.convertToZone(timezone, schedule[weekDay].hoursto):schedule[weekDay].hoursto);
        }
        else {
          this.friday = new DayScheduleData(5, 0, 0);
        }
      }
      if(saturday !== -1){
        this.saturday = new DayScheduleData(schedule[saturday].frequency, timezone?this.convertToZone(timezone, schedule[saturday].hoursfrom):schedule[saturday].hoursfrom, timezone?this.convertToZone(timezone, schedule[saturday].hoursto):schedule[saturday].hoursto);
      } else {

        if( allDay !== -1){
          this.saturday = new DayScheduleData(6, timezone?this.convertToZone(timezone, schedule[allDay].hoursfrom):schedule[allDay].hoursfrom, timezone?this.convertToZone(timezone, schedule[allDay].hoursto):schedule[allDay].hoursto);
        }
        else if(weekEnd !== -1){
          this.saturday = new DayScheduleData(6, timezone?this.convertToZone(timezone, schedule[weekEnd].hoursfrom):schedule[weekEnd].hoursfrom, timezone?this.convertToZone(timezone, schedule[weekEnd].hoursto):schedule[weekEnd].hoursto);
        }
        else {
          this.saturday = new DayScheduleData(6, 0, 0);
        }
      }
      if(sunday !== -1){
        this.sunday = new DayScheduleData(schedule[sunday].frequency, timezone?this.convertToZone(timezone, schedule[sunday].hoursfrom):schedule[sunday].hoursfrom, timezone?this.convertToZone(timezone, schedule[sunday].hoursto):schedule[sunday].hoursto);
      } else {

        if( allDay !== -1){
          this.sunday = new DayScheduleData(7, timezone?this.convertToZone(timezone, schedule[allDay].hoursfrom):schedule[allDay].hoursfrom, timezone?this.convertToZone(timezone, schedule[allDay].hoursto):schedule[allDay].hoursto);
        }
        else if(weekEnd !== -1){
          this.sunday  = new DayScheduleData(7, timezone?this.convertToZone(timezone, schedule[weekEnd].hoursfrom):schedule[weekEnd].hoursfrom, timezone?this.convertToZone(timezone, schedule[weekEnd].hoursto):schedule[weekEnd].hoursto);
        }
        else {
          this.sunday = new DayScheduleData(7, 0, 0);
        }
      }
    }
  }
  getAllValue() {
    if (this.nullValue !== -1) {
      return {};
    } else{
      return {
        ...this.monday.getEncodeValue(),
        ...this.tuesday.getEncodeValue(),
        ...this.wednesday.getEncodeValue(),
        ...this.thursday.getEncodeValue(),
        ...this.friday.getEncodeValue(),
        ...this.saturday.getEncodeValue(),
        ...this.sunday.getEncodeValue(),
      };
    }

  }

  convertToZone(timeZone, timeData): number {
    if (timeData === -1) {
      return 0;
    } else {
      var fmt   = "MM/DD/YYYY HH:mm:ss A";  // must match the input
      let input = "05/30/2014 " + this.formatted_string('00', timeData, 'l') + ":00:00";
      var newValue = momentTZ.tz(input, fmt, timeZone);

      newValue.utc();
      let t1 =  newValue.format(fmt);
      return Number(newValue.format("HH"))

    }
  }

  formatted_string(pad, user_str, pad_pos)
  {
    if (typeof user_str === 'undefined')
      return pad;
    if (pad_pos == 'l') {
      return (pad + user_str).slice(-pad.length);
    }
    else {
      return (user_str + pad).substring(0, pad.length);
    }
  }

}
export class addLocation {
  name?: string;
  color?: string;
  id?: string;

  constructor(eu) {
    this.name = eu.name;
    this.color = eu.color;
    this.id = eu.id;
  }

  //  TODO: update values with APIs

  static users(): addLocation[] {
    return [{ name: 'John Que', color: '' }];
  }

  //  TODO: update values with APIs

  static labels(): addLocation[] {
    return [
      { name: 'Headquarters', color: '' },
      { name: 'Corporate', color: '' },
    ];
  }
}

export class locationTable {
  id: number;
  name: string;
  labels: string;
  locationManager: string;
  health: string;
  color: string;

  constructor(ut) {
    this.name = ut.name;
    this.labels = ut.labels;

    this.locationManager = ut.locationManager;
    this.health = ut.health;
    this.color = ut.color;
  }
}

export class locationHealth {
  health: any;
  count: number;

  constructor(ut) {
    this.health = ut.health;
    this.count = ut.count;
  }

  static healthData(): locationHealth {
    return {
      count: 4,
      health: {
        healthy: 1,
        unhealthy: 0,
        at_risk: 0,
        unknown: 0,
      },
    };
  }
}

export class editLocation {
  name?: string;
  color?: string;
  id?: Number
  constructor(eu) {
    this.name = eu.name;
    this.id = eu.id;
  }

  //  TODO: update values with APIs

  static users(): editLocation[] {
    return [{ name: 'John Que' , id:1}];
  }

  //  TODO: update values with APIs

  static labels(): editLocation[] {
    return [{ name: 'Headquarters', id:1}, { name: 'Corporate', id:2 }];
  }
}

export class aedAssignmentsTable {
  aed_name: string;
  health: string;
  health_text: string;

  static aedHealth(): aedAssignmentsTable[] {
    return [
      { aed_name: 'C34309', health: 'active', health_text: '' },
      {
        aed_name: 'C34310',
        health: 'inactive',
        health_text: 'Battery Low, Training Pads Inserted',
      },
      { aed_name: 'C34311', health: 'inactive', health_text: 'Battery Low' },
      {
        aed_name: 'C34312',
        health: 'warning',
        health_text: 'Pads Expiring soon',
      },
    ];
  }
}
export class editLocations {
  name?: String;
  address_line_1?: String;
  address_line_2?: String;
  city?: String;
  state?: String;
  zip_code?: String;
  country: String;
  labels: any[];
  users: any[];
  allow_911: Boolean;
  privacy: Number;
  org_id?: number;
  location_schedule:any;
  constructor(ut: any, zoneString: any) {
    if (ut) {
      this.name = ut.name;
      this.address_line_1 = ut.address_line_1;
      this.address_line_2 = ut.address_line_2;
      this.city = ut.city;
      this.state = ut.state;
      this.zip_code = ut.zip_code;
      this.country = ut.country;
      this.labels = ut.labels;
      this.users = ut.users;
      this.allow_911 = ut.allow_911;
      this.privacy = ut.privacy;
      this.org_id = ut.org_id;
      if(ut.location_schedule && ut.location_schedule.length !== 0 && this.privacy === 1){
        this.location_schedule = new ScheduleData(ut.location_schedule, zoneString).getAllValue();
      } else {
        this.location_schedule = this.getEmptySchedule();
      }
    }
  }
  getEmptySchedule() {
    return {"1": [0,0],"2": [0,0],"3": [0,0],"4": [0,0],"5": [0,0],"6": [0,0],"7": [0,0]}
  }
}
