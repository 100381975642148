import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-av-pg-title-navigator',
  templateUrl: './av-pg-title-navigator.component.html',
  styleUrls: ['./av-pg-title-navigator.component.scss']
})
export class AvPgTitleNavigatorComponent implements OnInit {
  @Output() nextPageFunction: EventEmitter<any> = new EventEmitter();
  @Output() previousPageFunction: EventEmitter<any> = new EventEmitter();
  @Input() totalPages: any;
  @Input() currentPage: any;
  @Input() dataType: string;
  @Input() mode: ProgressSpinnerMode;
  enableNext = false;
  enablePrevious = false;
  color: ThemePalette = 'primary';
  value = 50;

  ngOnChanges(changes: SimpleChanges) {
    this.currentPage = changes.currentPage ? changes.currentPage.currentValue : this.currentPage;
    this.updateButtonStatus();
  }

  constructor() { }

  ngOnInit(): void {
    this.updateButtonStatus();
  }

  nextPage() {
    if (this.enableNext) {
      this.nextPageFunction.emit();
    }
  }

  previousPage() {
    if (this.enablePrevious) {
      this.previousPageFunction.emit();
    }
  }

  updateButtonStatus() {
    // Check if there is only page
    if (this.totalPages == this.currentPage && this.totalPages <= 1){
      this.enableNext = false;
      this.enablePrevious = false;
    }
    // check if it is last page
    else if (this.totalPages == this.currentPage){
      this.enableNext = false;
      this.enablePrevious = true;
    }
    // check if it is fist page
    else if (1 == this.currentPage){
      this.enableNext = true;
      this.enablePrevious = false;
    }
    // both way navigation is possible
    else {
      this.enableNext = true;
      this.enablePrevious = true;
    }
  }
}
