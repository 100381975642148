import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressbarService {

  progressBar: BehaviorSubject<any[]>
  progressBarStaus: any[];

  constructor() {
    this.progressBar  = new BehaviorSubject(this.progressBarStaus);
  }

  changeStatus(status){
    this.progressBarStaus = status;
    this.progressBar.next(status);
  }
}
