<app-av-pg>
  <app-av-pg-title>
    <app-av-pg-title-heading pg_title="Welcome to Avive!"></app-av-pg-title-heading>
  </app-av-pg-title>
  <mat-card class="av-card">
    <mat-card-content class="welcome-content">
      <div class="title-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start" fxLayoutAlign.lt-md="end start"
        fxLayoutGap="16px">
        <h4 id="account-title">{{accountDetails.account_title}}</h4>
        <button (click)="goToCreateAccount()" id="create-account" mat-flat-button color="primary">CREATE
          ACCOUNT</button>
      </div>
      <div class="content" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="start"
        fxLayoutGap="16px">
        <div id="account-content">{{accountDetails.account_content}} </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="av-card">
    <mat-card-content class="welcome-content">
      <div class="title-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start" fxLayoutAlign.lt-md="end start"
        fxLayoutGap="16px">
        <h4 id="incident-title">{{accountDetails.incident_title}}</h4>
        <button mat-flat-button color="primary">ACCESS INCIDENT RECORD</button>
      </div>
      <div class="content" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="start"
        fxLayoutGap="16px">
        <div id="incident-content">{{accountDetails.incident_content}} </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="av-card">
    <mat-card-content class="welcome-content">
      <div class="title-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start" fxLayoutAlign.lt-md="end start"
        fxLayoutGap="16px">
        <h4 id="download-title">{{accountDetails.download_title}}</h4>
        <button mat-flat-button color="primary">DOWNLOAD APP</button>
      </div>
      <div class="content" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="start"
        fxLayoutGap="16px">
        <div id="download-content">{{accountDetails.download_content}} </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="av-card">
    <mat-card-content class="welcome-content">
      <div class="title-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start" fxLayoutAlign.lt-md="end start"
        fxLayoutGap="16px">
        <h4 id="video-title">{{accountDetails.video_title}}</h4>
        <button mat-flat-button color="primary">LEARN MORE</button>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="start"
        fxLayoutGap="16px">
        <div class="content" id="video-content" fxFlex="100">
          <span class="video-box" fxFlex="100">
            <h4 class="video-content">embedded video</h4>
          </span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</app-av-pg>
