export const HEALTH = [
  {id: 'healthy', name: 'Healthy'},
  {id: 'unhealthy', name: 'Unhealthy'},
]


export const USER_ROLES = [
  {id: 3, role: "admin", name: 'Administrator'},
  {id: 2, role: "manager", name: 'Manager'},
  {id: 1, role: "responder", name: 'Responder'}
]


export const COUNTRIES = [
  { id: 1, name: 'United States'},
  { id: 2, name: 'Canada'}
]
