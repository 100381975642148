import {Injectable} from "@angular/core";
import {LocalStoreService} from "./local-store.service";
import {ActivatedRoute, Router} from "@angular/router";
import {User,IUser} from "../models/user.model";
import axios from "axios";
import {LOCAL_STORAGE} from 'app/shared/constants/local-storage';
import {ProgressbarService} from 'app/shared/services/progressbar.service';
import {AccountService} from "./account.service";
import {URLS} from "./urls";


axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  isAuthenticated: Boolean;
  signingIn: Boolean;
  return: string;
  APP_USER: any = {};
  signedIn = false;

  constructor(
      private ls: LocalStoreService,
      private router: Router,
      private route: ActivatedRoute,
      private ps: ProgressbarService,
      private accountService: AccountService
  ) {
    this.route.queryParams.subscribe(params => this.return = params['return'] || '/');
  }

  async signin(email, password) {
    return await axios.post<User>(URLS.login, {email, password});
  }


  public signout() {
    axios.get(URLS.logout);
    this.ls.clear();
    this.ps.changeStatus('determinate');
    this.accountService.changeAccount('');
    this.router.navigateByUrl('sessions/signin')
  }

  async mappingData() {
    return await axios.get(URLS.mapping_data);
  }

  getUser() {
    return this.ls.getItem(LOCAL_STORAGE.APP_USER);
  }

  async isLoggedIn(): Promise<Boolean> {
    try {
      await axios.get(URLS.login_check)
          .then((response) => {
          if (response.status === 200) {
              const user = new User(response.data.user as IUser);
              this.ls.setItem(LOCAL_STORAGE.APP_USER, user);
              return true;
            }
            if (response.status === 403) {
              return false;
            }
          })
    } catch (error) {
      return false;
    }
  }
}
