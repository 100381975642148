import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvCardTitleComponent } from './av-card-title/av-card-title.component';
import { MatCardModule } from '@angular/material/card';
import { AvCardSummaryComponent } from './av-card-summary/av-card-summary.component';
import { AvCardSummaryItemComponent } from './av-card-summary-item/av-card-summary-item.component';
import { FlexModule } from '@angular/flex-layout';
import { AvCardContentComponent } from './av-card-content/av-card-content.component';
import { AvCardFilterComponent } from './av-card-filter/av-card-filter.component';
import { AvCardComponent } from './av-card/av-card.component';
import { AvPgComponent } from './av-pg/av-pg.component';
import { AvPgBackComponent } from './av-pg-back/av-pg-back.component';
import { MatIconModule } from '@angular/material/icon';
import { AvPgTitleComponent } from './av-pg-title/av-pg-title.component';
import { AvPgTitleHeadingComponent } from './av-pg-title-heading/av-pg-title-heading.component';
import { AvPgTitleButtonsComponent } from './av-pg-title-buttons/av-pg-title-buttons.component';
import { AvPgTitleButtonComponent } from './av-pg-title-button/av-pg-title-button.component';
import { AvPgTitleNavigatorComponent } from './av-pg-title-navigator/av-pg-title-navigator.component';
import { AvHealthTagComponent } from './av-health-tag/av-health-tag.component';
import { MatButtonModule } from '@angular/material/button';
import { AvFullCardSummaryComponent } from './av-full-card-summary/av-full-card-summary.component';
import { AvFullCardSummaryItemComponent } from './av-full-card-summary-item/av-full-card-summary-item.component';
import { AvFullCardSummaryItemHeaderComponent } from './av-full-card-summary-item-header/av-full-card-summary-item-header.component';
import { AvFullCardSummaryItemDataNumberComponent } from './av-full-card-summary-item-data-number/av-full-card-summary-item-data-number.component';
import { AvFullCardSummaryItemDataKeyValueComponent } from './av-full-card-summary-item-data-key-value/av-full-card-summary-item-data-key-value.component';
import { AvDialogueBoxComponent } from './av-dialogue-box/av-dialogue-box.component';
import { SharedMaterialModule } from 'app/shared/shared-material.module';
import { AvTableWrapComponent } from './av-table-wrap/av-table-wrap.component';
import { AvNotificationProfileComponent } from './av-notification-profile/av-notification-profile.component';
import { AvAccountNameComponent } from './av-account-name/av-account-name.component';
import { SearchModule } from 'app/shared/search/search.module';
import { AvSectionsComponent } from './av-sections/av-sections.component';
import { AvAssignmentsDivComponent } from './av-assignments-div/av-assignments-div.component';
import { AvAssignmentsLabelComponent } from './av-assignments-label/av-assignments-label.component';
import { AvCardSummaryItemInlineComponent } from './av-card-summary-item-inline/av-card-summary-item-inline.component';
import { AvDeletePopupComponent } from './av-delete-popup/av-delete-popup.component';
import { AvSchedulesComponent } from './av-schedules/av-schedules.component';
import { AvTableNotificationsComponent } from './av-table-notifications/av-table-notifications.component';

@NgModule({
  declarations: [
    AvCardTitleComponent,
    AvCardSummaryComponent,
    AvCardSummaryItemComponent,
    AvCardContentComponent,
    AvCardFilterComponent,
    AvCardComponent,
    AvPgComponent,
    AvPgBackComponent,
    AvPgTitleComponent,
    AvPgTitleHeadingComponent,
    AvPgTitleButtonsComponent,
    AvPgTitleButtonComponent,
    AvPgTitleNavigatorComponent,
    AvHealthTagComponent,
    AvFullCardSummaryComponent,
    AvFullCardSummaryItemComponent,
    AvFullCardSummaryItemHeaderComponent,
    AvFullCardSummaryItemDataNumberComponent,
    AvFullCardSummaryItemDataKeyValueComponent,
    AvDialogueBoxComponent,
    AvTableWrapComponent,
    AvNotificationProfileComponent,
    AvSectionsComponent,
    AvAssignmentsDivComponent,
    AvAssignmentsLabelComponent,
    AvCardSummaryItemInlineComponent,
    AvAccountNameComponent,
    AvSectionsComponent,
    AvDeletePopupComponent,
    AvSchedulesComponent,
    AvTableNotificationsComponent
  ],
  exports: [
    AvCardTitleComponent,
    AvCardTitleComponent,
    AvCardSummaryComponent,
    AvCardSummaryItemComponent,
    AvCardContentComponent,
    AvCardFilterComponent,
    AvCardComponent,
    AvPgComponent,
    AvPgBackComponent,
    AvPgTitleComponent,
    AvPgTitleHeadingComponent,
    AvPgTitleButtonsComponent,
    AvPgTitleButtonComponent,
    AvPgTitleNavigatorComponent,
    AvHealthTagComponent,
    AvFullCardSummaryComponent,
    AvFullCardSummaryItemComponent,
    AvFullCardSummaryItemHeaderComponent,
    AvFullCardSummaryItemDataKeyValueComponent,
    AvFullCardSummaryItemDataNumberComponent,
    AvDialogueBoxComponent,
    AvTableWrapComponent,
    AvNotificationProfileComponent,
    AvSectionsComponent,
    AvAssignmentsDivComponent,
    AvAssignmentsLabelComponent,
    AvCardSummaryItemInlineComponent,
    AvAccountNameComponent,
    AvSectionsComponent,
    AvDeletePopupComponent,
    AvSchedulesComponent,
    AvTableNotificationsComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    FlexModule,
    MatIconModule,
    MatButtonModule,
    SharedMaterialModule,
    SearchModule,
  ],

})
export class AvModule {}
