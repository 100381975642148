import { Injectable } from "@angular/core"
import { BehaviorSubject, Subject } from "rxjs"
import { Notification } from "../models/notification.model"
import { Incident } from "../models/incident.model"

@Injectable({
    providedIn: 'root',
  })
  export class DashboardService {
    notificationsDataUpdate = new BehaviorSubject(null);
    incidentsDataUpdate = new BehaviorSubject(null);

    constructor() {}
  }


