import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-av-table-wrap',
  templateUrl: './av-table-wrap.component.html',
  styleUrls: ['./av-table-wrap.component.scss']
})
export class AvTableWrapComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
