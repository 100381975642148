<div class="search-bar-wide " [ngClass]="{ open: isOpen }">
  <div>
    <input class="default-bg" [formControl]="searchCtrl" type="text" [placeholder]="placeholder" (click)="hidePlaceHolder()" (blur)="showPlaceHolder()" />
    <span class="ghost" *ngIf="!searchCtrl.value && !clicked">
      <mat-icon class="search-icon" [svgIcon]="'search'"></mat-icon>
      Search
    </span>
    <mat-icon class="close" (click)="clear()" *ngIf="searchCtrl.value">close</mat-icon>
  </div>
</div>
