export class Pagination {
  pageSize?: number;
  pageLength?: number;
  pageIndex?: number;
  pageSizeOptions?:number[]

  constructor(pg) {
   this.pageSize = pg.pageSize;
   this.pageLength = pg.pageLength;
   this.pageIndex = pg.pageLength;
   this.pageSizeOptions = pg.pageSizeOptions;

 }

 static pageData(): Pagination{
    return {
     pageSize : 0,
     pageLength : 0,
     pageIndex: 1,
     pageSizeOptions:[12,24,36]
    }
  }
}
