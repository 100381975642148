<span *ngIf="accountName" [matMenuTriggerFor]="organizationmenu" class="account-name d-flex align-items-center justify-contents-start cursor-pointer" matRipple>
  <span class="name">{{ellipsify(accountName)}}</span>
  <mat-icon id ="downarrow">arrow_drop_down</mat-icon>
</span>
<mat-menu #organizationmenu="matMenu">
  <button mat-menu-item>
    <mat-icon>corporate_fare</mat-icon>
    <span>{{accountName}}</span>
  </button>
  <button mat-menu-item  (click)="goToAccountSelect()">
    <mat-icon>checklist_rtl</mat-icon>
    Select organization
  </button>
</mat-menu>
