import { Component, OnInit, ViewChild } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmPassword } from 'app/shared/models/user.model';
import { AuthService } from 'app/shared/services/auth.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {
  SUCCESS_MESSAGES,
  ERROR_MESSAGES,
} from 'app/shared/constants/messages';
import { PasswordStrengthValidator } from 'app/shared/helpers/password-strength.validator';
import { ProgressbarService } from 'app/shared/services/progressbar.service';
import { ConfirmPasswordValidator } from 'app/shared/helpers/match-fields.validator';


@Component({
  selector: 'app-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.scss'],
})
export class ConfirmPasswordComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;

  message: string;
  confirmationForm: FormGroup;
  signupDisabled = true;
  uidb64: string;
  tokenValue: string;
  passwordReset: boolean;

  constructor(
    public activatedRoute: ActivatedRoute,
    private auth: AuthService,
    public _snackBar: MatSnackBar,
    public router: Router,
    private ps: ProgressbarService,
  ) {
    this.uidb64 = this.activatedRoute.snapshot.paramMap.get('token-id');
    this.tokenValue = this.activatedRoute.snapshot.paramMap.get('token');
  }
  ngOnInit(): void {
    this.confirmationForm = new FormGroup({
      password: new FormControl('',
      Validators.compose([
        Validators.required,
        Validators.maxLength(75),
        Validators.minLength(6),
        PasswordStrengthValidator
      ])),
      confirmPassword: new FormControl('', [ Validators.required ]),
    },{
      validators: ConfirmPasswordValidator('password', 'confirmPassword')
    });
  }
  submitPassword() {
    this.ps.changeStatus('indeterminate');
    const submittedData = this.confirmationForm.value;
    const submittedValues: ConfirmPassword = {
      password: submittedData.password,
      token: this.tokenValue,
      uidb64: this.uidb64,
    };
    let url: string;
    this.auth
      .confirmPassword(submittedValues)
      .then((response: any) => {
        this.ps.changeStatus('determinate');
        if (response.status === 200) {
          this.message = SUCCESS_MESSAGES.reset_success;
          this.passwordReset = true ;
          this.openSnakbar(this.message);
        } else {
          this.message = ERROR_MESSAGES.reset_error;
          this.openSnakbar(this.message);
        }
      })
      .catch((error) => {
        if(error.response.status === 400){
          this.message = ERROR_MESSAGES.reset_error;
          this.openSnakbar(this.message);
        }
        this.ps.changeStatus('determinate');
      });
  }
  //To open snack bar
  openSnakbar(message) {
    this._snackBar.open(message, '', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 2000,
    });
  }
  // verify password
  comparePasswords() {
    const password = this.confirmationForm.value.password;
    const confirmPassword = this.confirmationForm.value.confirmPassword;
    if (confirmPassword === password) {
      this.signupDisabled = false;
      return true;
    } else {
      this.signupDisabled = true;
      return false;
    }
  }
  goToSignin() {
    this.router.navigateByUrl('sessions/signin');
  }
}
