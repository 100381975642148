import { Component, OnInit } from '@angular/core';
import { LoginService } from 'app/shared/services/login.service';
import { User } from 'app/shared/models/user.model';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-welcome-layout',
  templateUrl: './welcome-layout.component.html',
  styleUrls: ['./welcome-layout.component.scss'],
})
export class WelcomeLayoutComponent implements OnInit {
  public layoutConf: any;
  notificationIcon: string;
  user: string;

  public scrollConfig = {};

  constructor(
    public loginService: LoginService,
    public translate: TranslateService,
    private router: Router,
  ) { }

  ngOnInit() {
    const userData = this.loginService.getUser();
    const u = new User(userData)
    this.user = u.getDisplayName();
  }

  goToDashBoard() {
    this.router.navigateByUrl('dashboard')
  }
}
