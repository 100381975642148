import { Component, OnInit, OnDestroy, AfterViewInit, EventEmitter, Input, ViewChildren  , Output, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../services/login.service';
import { EgretNotifications2Component } from '../egret-notifications2/egret-notifications2.component';
import { HeaderSideService } from "../../../shared/services/header-side.service";
import { Subscription } from "rxjs";
import { LayoutService } from 'app/shared/services/layout.service';
import { User } from 'app/shared/models/user.model';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html'
})
export class HeaderSideComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() notificPanel;
  @ViewChildren(EgretNotifications2Component) noti;

  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public layoutConf: any;

  notificationIcon: string;
  user: string;
  constructor(
    private headService: HeaderSideService,
    public translate: TranslateService,
    public loginService: LoginService,
    private layout: LayoutService
  ) { }
  ngOnInit() {
    this.layoutConf = this.layout.layoutConf;
    this.iconTypeMenuTitle = this.headService.iconTypeMenuTitle;
    this.menuItemsSub = this.headService.menuItems$.subscribe(menuItem => {
      this.menuItems = menuItem;
      this.notificationIcon = this.menuItems[0].svgIcon
      //Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
        item => item.type === "icon"
      ).length;
    });
    const userData = this.loginService.getUser();
    const u = new User(userData)
    this.user = u.getDisplayName();
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    });
  }
}
