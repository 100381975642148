import { Component, OnInit, HostListener } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../../../services/theme.service';
import { LayoutService } from '../../../services/layout.service';

@Component({
  selector: 'app-incident-layout',
  templateUrl: './incident-layout.component.html',
  styleUrls: ['./incident-layout.component.scss']
})
export class IncidentLayoutComponent implements OnInit {

  public isModuleLoading: Boolean = false;

  constructor(
    public translate: TranslateService,
    public themeService: ThemeService,
    private layout: LayoutService
  ) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }

  ngOnInit() { }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.layout.adjustLayout(event);
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() { }
  
}
