import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-av-dialogue-box',
  templateUrl: './av-dialogue-box.component.html',
  styleUrls: ['./av-dialogue-box.component.scss']
})
export class AvDialogueBoxComponent implements OnInit {
  layoutConf: any;
  @Output() closeDlg: EventEmitter<any> = new EventEmitter();
  @Output() saveData: EventEmitter<any> = new EventEmitter();
  @Input() pop_title: string;
  @Input() isDisable: boolean = false;
  @Input() isHide: boolean = false;
  @Input() action: string;
  @Input() closeText: string;
  @Input() isCancel: boolean = false;
  constructor( ) {
    this.action = 'SAVE'
    this.closeText = 'EXIT'
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void { }

  closeDialogue() {
    this.closeDlg.emit();
  }

  save() {
    this.saveData.emit();
  }
}
