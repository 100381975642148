import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-av-assignments-label',
  templateUrl: './av-assignments-label.component.html',
  styleUrls: ['./av-assignments-label.component.scss']
})
export class AvAssignmentsLabelComponent implements OnInit {
  @Input() label: string;
  @Input() optional: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
