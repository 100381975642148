import { Injectable } from '@angular/core';
import { Organization } from '../models/organization.model';
import axios from 'axios';
import { BehaviorSubject } from 'rxjs';
import { LocalStoreService } from './local-store.service';
import { LOCAL_STORAGE } from '../constants/local-storage';
import { URLS } from './urls';

axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  accountName: BehaviorSubject<any[]>;
  account: any[];

  constructor(private ls: LocalStoreService) {
    this.account = this.ls.getItem(LOCAL_STORAGE.ACCOUNT);
    this.accountName = new BehaviorSubject(this.account ? this.account : ['']);
  }

  async createOrganization(organizationDetails) {
    return await axios.post<Organization>(
      URLS.organization,
      organizationDetails
    );
  }

  async getOrganizations(searchQuery, pageIndex) {
    let params: any = {};
    if (searchQuery) {
      params.name = encodeURI(searchQuery);
    }
    if (pageIndex) {
      params.page_no = pageIndex;
    }
    return await axios.get<Organization>(URLS.organization, { params });
  }

  async editOrganization(organizationDetails, id) {
    return await axios.put<Organization>(
      URLS.organization + id + '/',
      organizationDetails
    );
  }

  async getOrganization(id) {
    return await axios.get<Organization>(URLS.organization + id + '/');
  }

  changeAccount(account) {
    this.account = account;
    this.accountName.next(this.account);
  }
}
