import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-av-card-summary-item',
  templateUrl: './av-card-summary-item.component.html',
  styleUrls: ['./av-card-summary-item.component.scss']
})
export class AvCardSummaryItemComponent implements OnInit {
  @Input() header: string;
  @Input() value: string;
  constructor() { }

  ngOnInit(): void {
  }

}
