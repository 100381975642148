import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  public searchTerm: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public searchTerm$: Observable<string> = this.searchTerm.asObservable();
  search: BehaviorSubject<boolean>
  searchStatus: false;

  constructor() {
    this.search  = new BehaviorSubject(this.searchStatus);
  }

  changeSearchStatus(status){
    this.searchStatus = status;
    this.search.next(status);
  }
}
