import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-av-sections',
  templateUrl: './av-sections.component.html',
  styleUrls: ['./av-sections.component.scss']
})
export class AvSectionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
