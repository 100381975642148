export interface IBasicOrganization {
    id: number;
    name: string;
}

export class Organization {
  id?: number;
  name: string;
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  state?: string;
  zip_code?: string;
  phone_number?: string;
  email: string;
  country: string;
  account_status?: number;

  constructor(organizationData) {
    if(organizationData) {
      this.id = organizationData.id;
      this.name = organizationData.name;
      this.phone_number = organizationData.phoneNumber;
      this.email = organizationData.emailId;
      this.address_line_1 = organizationData.streetAddress;
      this.address_line_2 = organizationData.streetAddress2;
      this.zip_code = organizationData.zipCode;
      this.city = organizationData.city;
      this.state = organizationData.state;
      this.country = organizationData.country;
    }
  }
}
