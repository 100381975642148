<ng-container style="height: 30%;">
    <!-- Filter -->
    <ng-container *ngIf="isFilterable" [matColumnDef]="this.rowActionIcon">
      <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="filter">
      </mat-form-field>
    </ng-container>
  
    <app-av-table-wrap>
      <table mat-table class="mat-table-db" [dataSource]="tableDataSource" id="notification-table">
        <ng-container matColumnDef="time_stamp">
          <th mat-header-cell *matHeaderCellDef [ngClass]="'w-20'">Date</th>
          <td mat-cell *matCellDef="let element"  [ngClass]="!element.read ? 'true' : ''">{{element.time_stamp | date: 'MM/dd/yy'}}</td>
        </ng-container>

        <ng-container matColumnDef="display_text">
          <th mat-header-cell *matHeaderCellDef [ngClass]="'w-50'">Alert</th>
          <td mat-cell *matCellDef="let element">
            <span class="capitalize" [ngClass]="!element.read ? 'true' : ''">{{element.display_text}}
              <span *ngIf="element.tooltip">
                <mat-icon class="error-icon">error</mat-icon>
              </span>
            </span>            
          </td>
        </ng-container>

        <ng-container matColumnDef=' '>
          <th mat-header-cell *matHeaderCellDef [ngClass]="'w-20'"></th>
          <td mat-cell *matCellDef="let element">
            <span class="capitalize float-right" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="11px">
              <span class="dismiss" >DISMISS</span>
              <button class="close-icon" mat-icon-button aria-label="Exit" matTooltip="Dismiss">
                <mat-icon>close</mat-icon>
              </button>
              <a class="download-text" fxFlex (click)="goToPage(element)" *ngIf="element.display_action">
                <span class="goToText">{{element.display_action}}</span>
                <mat-icon class="goToIcon" matTooltip="{{element.display_action}}">arrow_right_alt</mat-icon>
              </a>
            </span>
          </td>
        </ng-container>
        <div *ngIf="isHeader">
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        </div>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="cursor-pointer" ></tr>
      </table>
      <div class="no-records-found" *ngIf="!recordsFound">
        <span>{{noRecordsFound}}</span>
      </div>
    </app-av-table-wrap>
  
    <!-- Pagination -->
    <mat-paginator *ngIf="isPageable"
                   [pageSizeOptions]="paginationSizes"
                   [pageSize]="defaultPageSize"
                   showFirstLastButtons>
    </mat-paginator>
  </ng-container>
