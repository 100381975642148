import { AbstractControl, ValidationErrors } from "@angular/forms"
import { ERROR_MESSAGES } from "../constants/messages";

export const PasswordStrengthValidator = function (control: AbstractControl): ValidationErrors | null {

  let value: string = control.value || '';
  let msg = "";
  if (!value) {
    return null
  }

  let upperCaseCharacters = /[A-Z]+/g;
  let lowerCaseCharacters = /[a-z]+/g;
  let numberCharacters = /[0-9]+/g;
  let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (upperCaseCharacters.test(value) === false || lowerCaseCharacters.test(value) === false  || numberCharacters.test(value) === false || specialCharacters.test(value) === false || value.length < 8) {
    return {
      passwordStrength: ERROR_MESSAGES.password_strength_error
    }

  }
}
