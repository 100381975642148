import { Organization } from "./organization.model";
import { Location, Schedule, DaySchedule } from "./location.model";
import { ILabel, Label } from "./label.model";
import { User } from "./user.model";
import * as momentTZ from 'moment-timezone'

export class AEDDetails {
  aed_device?: AEDDevice;
  aed_device_id?: string;
  aed_schedule?: Schedule;
  date_added?: string;
  display_name?: string;
  floor_num?: number;
  id?: number;
  labels?: Label[] = [];
  lat_lng?: any;
  location?: Location[] = [];
  organization?: Organization[] = [];
  permanent?: boolean;
  placement_desc?: string;
  primary_manager?: User;
  health?: number;
  health_status?: string;
  health_color?: string;
  aed_device_name?: String;
  public?: number;
  users?: User[] = [];

  constructor(aed) {
    this.aed_device = new AEDDevice(aed.aed_device);
    this.aed_device_id = aed.aed_device_id;
    this.aed_schedule = aed.aed_schedule;
    this.date_added = aed.date_added;
    this.display_name = aed.display_name;
    this.floor_num = aed.floor_num;
    this.id = aed.id;
    // Add labels
    if (aed.labels !== undefined && aed.labels !== null) {
      for (let each of aed.labels) {
        this.labels.push(new Label(each as ILabel));
      }
    }
    this.lat_lng = aed.lat_lng;
    if(aed.location !== null) {
      this.location = {
        ...this.location,
        ...aed.location,
      };
    }
    this.organization = {
      ...this.organization,
      ...aed.organization,
    };
    this.permanent = aed.permanent;
    this.placement_desc = aed.placement_desc;
    this.primary_manager = aed.primary_manager ? aed.primary_manager : '';
    this.public = aed.public;
    this.id = aed.id;
    this.aed_device_id = aed.aed_device_id;
    this.health_status = aed.health_status?aed.health_status:'';
    this.health_color = aed.health_color?aed.health_color:'';
    this.aed_device_name = aed.aed_device;
    if (aed.aed_device_name !== undefined && aed.aed_device_name !== null) {
      this.aed_device_name = aed.aed_device_name;
    }
    // adding users
    if (aed.users !== undefined && aed.users !== null) {
      for (let each of aed.users) {
        this.users.push(new User(each));
      }
    }
    if (aed.aed_schedule !== undefined && aed.aed_schedule !== null) {
      this.aed_schedule = new Schedule(aed.aed_schedule);
    }

  }
}
export class AEDDevice {
  battery_level: number;
  battery_low: string;
  build_ver: number;
  created_at: string;
  display_name: string;
  fully_functional: boolean;
  functional: string;
  health: number;
  health_issues: string;
  hw_ver: number;
  include_in_emergencies: boolean;
  last_updated: string;
  lost_or_stolen: string;
  modified_at: string
  name: string
  out_of_temp: string;
  pad_exp_dt: string;
  pad_ser_num: string;
  pad_type: number
  password: string;
  self_test: string;
  self_test_timestamp: string;
  self_test_type: string;
  serial_number: string;
  sys_ver: number;
  device_health?: any[] = [];

  constructor(aedDevice) {
    this.battery_level = aedDevice.battery_level;
    this.battery_low = aedDevice.battery_low;
    this.build_ver = aedDevice.build_ver;
    this.created_at = aedDevice.created_at;
    this.display_name = aedDevice.display_name;
    this.fully_functional = aedDevice.fully_functional;
    this.functional = aedDevice.functional;
    this.health = aedDevice.health;
    this.health_issues = aedDevice.health_issues;
    this.hw_ver = aedDevice.hw_ver;
    this.include_in_emergencies = aedDevice.include_in_emergencies;
    this.last_updated = aedDevice.last_updated;
    this.lost_or_stolen = aedDevice.lost_or_stolen;
    this.modified_at = aedDevice.modified_at;
    this.name = aedDevice.name;
    this.out_of_temp = aedDevice.out_of_temp;
    this.pad_exp_dt = aedDevice.pad_exp_dt;
    this.pad_ser_num = aedDevice.pad_ser_num;
    this.pad_type = aedDevice.pad_type
    this.password = aedDevice.password;
    this.self_test = aedDevice.self_test;
    this.self_test_timestamp = aedDevice.self_test_timestamp;
    this.self_test_type = aedDevice.self_test_type;
    this.serial_number = aedDevice.serial_number;
    this.sys_ver = aedDevice.sys_ver;

    if(aedDevice.device_health !== undefined && aedDevice.device_health !== null) {
      this.device_health = aedDevice.device_health;
    }
  }

  softwareVersion() {
    return this.sys_ver+"."+this.build_ver;
  }

  getAEDName() {
    const aedName = this.name !== null ? this.name : this.display_name;
    return aedName;
  }
}

export interface IAEDDetailSummary {
  battery_level: string;
  other_issues: string;
  pad_cartridge: string;
  sync_status: string;
}

export interface IAEDListSummary {
  aeds: number;
  overall_health: {
    healthy: number;
    unhealthy: number;
    at_risk: number;
    unknown: number;
  };
  battery: {
    good: number;
    low: number;
    unknown: number;
  };
  pad_cartridges: {
    good: number;
    replace: number;
    upcoming: number;
    're-install': number;
    unknown: number;
  };
  other_issues: {
    critical_test: number;
    training: number;
    software: number;
    temp: number;
    other: number;
  };
  sync_status: {
    synced: number;
    unsynced: number;
  };
}

export class AED {
  aed: AEDDetails | AEDDetails[];
  summary?: IAEDListSummary;
  detailSummary?: IAEDDetailSummary;
  mapData?: any;

  constructor(aed) {
    if (typeof aed == 'object') {
      if(Array.isArray(aed.data)){
        this.aed = [];
        for (let each of aed.data) {
          this.aed.push(new AEDDetails(each));
        }
      } else {
        this.aed = new AEDDetails(aed.data);
      }
      // adding summary
      if (aed.summary) {
        if (aed.summary.battery_level) {
          this.detailSummary = {
            ...this.detailSummary,
            ...aed.summary,
          };
        } else if (aed.summary.aeds) {
          this.summary = {
            ...this.summary,
            ...aed.summary,
          };
        }
      }
      if (aed.mapData) {
        this.mapData = {
          ...this.mapData,
          ...aed.mapData,
        };
      }
    }
  }

  getOverallDeviceHealth() {
    if(!Array.isArray(this.aed)){
      let hIssues: any[] = this.aed.aed_device?.health_issues ? this.aed.aed_device?.health_issues?.split(',') : [];
      let health: any = hIssues.map((key) => ({
        type: key,
        value: this.mapData?.device_health[key],
      }));
      let health_issues: any = {
        battery: '',
        pad_cartridges: [],
        sync_status: undefined,
        other_issues: []
      }
      health.map(item=>{
        if(item.value == 'Low'){
          health_issues.battery = {value: 'Low', color: 'red'};
        }
        if(item.value == 'Good'){
          health_issues.battery = {value: 'Good', color: 'green'};
        }
        if(item.value == 'Replace'){
          health_issues.pad_cartridges.push({value: 'Replacement Required', color: 'red'});
        }
        if(item.value == 'Re-Install'){
          health_issues.pad_cartridges.push({value: 'Re-Install Cartridge', color: 'red'});
        }
        if(item.value == 'Training'){
          health_issues.other_issues.push({value: 'Training Pads are Inserted', color: 'red'});
        }
        if(item.value == 'Critical Test'){
          health_issues.other_issues.push({value: 'Failed Critical Self-Test', color: 'red'});
        }
        if(item.value == 'Software'){
          health_issues.other_issues.push({value: 'Needs Software Update', color: 'yellow'});
        }
        if(item.value == 'Temp'){
          health_issues.other_issues.push({value: 'Temperature Warning', color: 'yellow'});
        }
        // 4: "Upcoming" ??
        // 99: "Unknown" ??
      });
      return health_issues;
    }
  }
}

// Old models created for local data begins here
export class AedInfo {
  aedname?: string;
  location?: string;
  permenent?: string;
  floor?: number;
  placement?: string;
  public?: string;
  wifipin?: string;
  availablehours?: string;
  label?: string;
  history?: string;
  product?: string;
  expdate?: string;
  number?: string;
  version?: string;
  sundayhours?: string;
  mondayhours?: string;
  tuesdayhours?: string;
  wednesdayhours?: string;
  thursdayhours?: string;
  fridayhours?: string;
  satdayhours?: string;
  permenentdata?: boolean;
  publicData?: number;
  battery_level?: { status: string; color: string };
  pad_cartridge?: any[];
  exp_on?: { date: string; color: string };
  sync_data?: any[];
  sync_status?: { status: string; color: string };
  other_issues?: any[];

  constructor(ui) {
    if (ui) {
      this.aedname = ui.aed_device_id;
      this.location = ui.location.city;
      this.permenent = ui.permenent;
      this.floor = ui.floor_num;
      this.placement = ui.placement_desc;
      this.public = ui.public;
      this.wifipin = ui.wifipin;
      this.availablehours = ui.availablehours;
      this.label = ui.label;
      this.history = ui.history;
      this.product = ui.aed_device.name;
      this.expdate = ui.aed_device.pad_exp_dt;
      this.number = ui.aed_device.serial_number;
      this.version = ui.aed_device.sys_ver;
      this.sundayhours = ui.sundayhours;
      this.mondayhours = ui.mondayhours;
      this.tuesdayhours = ui.tuesdayhours;
      this.wednesdayhours = ui.wednesdayhours;
      this.thursdayhours = ui.thursdayhours;
      this.fridayhours = ui.fridayhours;
      this.satdayhours = ui.satdayhours;
      this.permenentdata = ui.permanent;
      this.publicData = ui.public;
    }
  }

  // TODO: update values with APIs
  static aedDetails(): AedInfo {
    return {
      aedname: 'C34311',
      location: 'Law Office San Francisco',
      permenent: 'Permanent',
      floor: 2,
      placement: 'Across from the elevators on the 2nd floor',
      public: 'public',
      wifipin: 'XXXX',
      label: 'Headquarters',
      history: 'May 10, 2019',
      product: '[Product Name]',
      expdate: '[Pad Expiration Date]',
      number: '[Serial Number]',
      version: '[Software Version #]',
      availablehours: 'May 10, 2019',
      sundayhours: 'Sunday: Not Available',
      mondayhours: 'Monday: 9AM-6PM',
      tuesdayhours: 'Tuesday: 9AM-6PM',
      wednesdayhours: 'Wednesday: 10AM-5PM',
      thursdayhours: 'Thursday: 9AM-6PM',
      fridayhours: 'Friday: 9AM-6PM',
      satdayhours: 'Saturday: 11AM-10PM',
    };
  }

  static deviceHealth(): AedInfo {
    return {
      battery_level: { status: 'Good', color: 'green' },
      pad_cartridge: [
        { msg: 'Re-install Cartridge', color: 'red' },
        { msg: 'Expiring on MM/DD/YYYY', color: 'yellow' },
        { msg: 'Good', color: 'green' },
      ],
      sync_data: [
        { status: 'Synced', color: 'green' },
        { status: 'Not Synced', color: 'yellow' },
      ],
      other_issues: [
        { issue: 'Failed Critical Self-Test', color: 'red' },
        { issue: 'Training Pads are Inserted', color: 'red' },
        { issue: 'Temperature Warning', color: 'yellow' },
        { issue: 'Needs software update', color: 'yellow' },
        { issue: 'None', color: 'yellow' },
      ],
    };
  }
}

// Assigned users table model
export class AssignedUsers {
  user_name: string;
  user_role: string;
  labels: string;
  crtificate: boolean;

  // TODO: update values with APIs
  static assignedUsers(): AssignedUsers[] {
    return [
      {
        user_name: 'Abdul Price (primary contact)',
        user_role: 'Admin',
        labels: 'Nurse',
        crtificate: true,
      },
      {
        user_name: 'Sienna Avila',
        user_role: 'Admin',
        labels: '',
        crtificate: true,
      },
      {
        user_name: 'Faye Redman',
        user_role: 'Manager',
        labels: 'Nurse',
        crtificate: true,
      },
      {
        user_name: 'Manveer Parks',
        user_role: 'Manager',
        labels: '',
        crtificate: true,
      },
      {
        user_name: 'Dilan Goulding',
        user_role: 'Responder',
        labels: '',
        crtificate: false,
      },
      {
        user_name: 'Cadi Rojas',
        user_role: 'Responder',
        labels: '',
        crtificate: true,
      },
    ];
  }
}

// TODO: update values with APIs
export class AedAssignment {
  name?: string;
  color?: string;
  constructor(eu) {
    this.name = eu.name;
    this.color = eu.color;
  }
  //  TODO: update values with APIs
  static userData(): AedAssignment[] {
    return [{ name: 'John Que', color: '' }];
  }
}

export class DeviceInfo {
  battery_level?: { status: string; color: string };
  pad_cartridge?: any[];
  sync_data?: any[];
  other_issues?: any[];

  constructor(ui) {
    if (ui) {
      this.battery_level = { status: ui.battery_level, color: '' };
      this.pad_cartridge = [{ status: ui.pad_cartridge, color: '' }];
      this.sync_data = [{ status: ui.sync_status, color: '' }];
      this.other_issues = [{ status: ui.other_issues, color: '' }];
    }
  }
}

export class OverallHealth {
  overall_health?: any[];
  constructor(ui) {
    if (ui) {
      this.overall_health = [
        { value: ui.healthy, name: 'Healthy', id: 'healthy' },
        {
          value: ui.unhealthy,
          name: 'UnHealthy',
          id: 'unhealthy',
        },
        { value: ui.at_risk, name: 'At Risk', id: 'risk' },
        { value: ui.unknown, name: 'Unknown', id: 'unknown' },
      ];
    }
  }
}
export class DaysSchedule {
  dayEncoding = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday',
  };
  start: number;
  end: number;
  day: number;
  dayText: string;
  availability = true;
  constructor(day: number, start: number, end: number) {
    if (day!== undefined && start !== undefined && end !== undefined) {

      this.day = day
      this.start = start;
      this.end = end;
      if (this.start === 0 && this.end === 0) {
        this.availability = false;
      }

    }

  }

  getEncodeValue() {
    // let dayValue = this.day;
    const dayValue = this.day
   let x = {
  [dayValue] : [this.start, this.end],
    };
    return x
  }
}
export class editAed {
  aed_device?: string;
  floor_num?: number;
  labels?: any[];
  users?: any[];
  placement_desc?: string;
  org_id?: number;
  public: number;
  allow_911?: boolean;
  permanent?: boolean;
  location: number;
  primary_manager?: number;
  aed_schedule?: any;
  include_in_emergencies?: boolean;
  lost_or_stolen?: boolean;
  aed_device_id?: number;
  name?:String;
  constructor(ut: any) {
    if (ut) {
      this.name= ut.display_name?ut.display_name:'';
      this.floor_num = ut.floor_number;
      this.placement_desc = ut. placement_desc;
      this.public = ut.public;
      this.permanent = ut.permanent ;
      this.location = ut.location;
      this.labels = ut.labels;
      this.include_in_emergencies = ut.include_in_emergencies;
      this.lost_or_stolen = ut.lost_or_stolen;
      this.aed_device_id = ut.aed_device_id;
      if(ut.publicAvailabilityFields && ut.publicAvailabilityFields.length !== 0 && this.public === 1){
        this.aed_schedule = new ScheduleData(ut.publicAvailabilityFields, ut.zoneString).getAllValue();
      } else {
        this.aed_schedule = this.getEmptySchedule();
      }

      this.allow_911 = ut.allow_911;
      this.primary_manager = ut.primary_manager;
      this.users = ut.users;
      this.include_in_emergencies = ut.include_in_emergencies;
    }
  }

  getEmptySchedule() {
    return {"1": [0,0],"2": [0,0],"3": [0,0],"4": [0,0],"5": [0,0],"6": [0,0],"7": [0,0]}
  }
}
export class MapData {
  health_data?: any[] = [];
  device_data?: any[] = [];
  constructor(health) {
    if (health) {
      this.health_data = health.health_issues?.overall_health;
      this.device_data = health.health_issues?.device_health;
    }
  }
  getHealthData() {
    return Object.keys(this.health_data).map((key) => ({
      type: key,
      value: this.health_data[key],
    }));
  }
  getDeviceData() {
    let deviceData = Object.keys(this.device_data).map((key) => ({
      type: key,
      value: this.device_data[key],
    }));
    deviceData.map(item=>{
      if(item.value == 'Low'){
        item.value = 'Battery Low'
      }
      if(item.value == 'Replace'){
        item.value = 'Replacement Required'
      }
      if(item.value == 'Re-Install'){
        item.value = 'Re-install Cartridge'
      }
      if(item.value == 'Training'){
        item.value = 'Training Pads are Inserted'
      }
      if(item.value == 'Critical Test'){
        item.value = 'Failed Critical Self-Test'
      }
      if(item.value == 'Software'){
        item.value = 'Needs Software Update'
      }
      if(item.value == 'Temp'){
        item.value = 'Temperature Warning'
      }
    })
    return deviceData
  }

}

export class DayScheduleData {
  dayEncoding = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday',
  };
  start: number;
  end: number;
  day: number;
  dayText: string;
  availability = true;
  constructor(day: number, start: number, end: number) {
    if (day !== undefined && start !== undefined && end !== undefined) {
      this.day = day;
      this.dayText = this.dayEncoding[day];
      this.start = start;
      this.end = end;
      if (this.start === 0 && this.end === 0) {
        this.availability = false;
      }
    }
  }
  getEncodeValue() {
    // let dayValue = this.day;
    const dayValue = this.day
   let x = {
  [dayValue] : [this.start, this.end],
    };
    return x
  }
}
export class ScheduleData {
  monday: DayScheduleData;
  tuesday: DayScheduleData;
  wednesday: DayScheduleData;
  thursday: DayScheduleData;
  friday: DayScheduleData;
  saturday: DayScheduleData;
  sunday: DayScheduleData;
  nullValue: Number;

  constructor(schedule, timezone?: string) {
    if (schedule !== undefined && Object.keys(schedule).length !== 0) {
      let monday: number = schedule.findIndex((item) => item.frequency === 1);
      let tuesday: number = schedule.findIndex((item) => item.frequency === 2);
      let wednesday: number = schedule.findIndex(
        (item) => item.frequency === 3
      );
      let thursday: number = schedule.findIndex((item) => item.frequency === 4);
      let friday: number = schedule.findIndex((item) => item.frequency === 5);
      let saturday: number = schedule.findIndex((item) => item.frequency === 6);
      let sunday: number = schedule.findIndex((item) => item.frequency === 7);
      let weekDay: number = schedule.findIndex((item) => item.frequency === 8);
      let weekEnd: number = schedule.findIndex((item) => item.frequency === 9);
      let allDay: number = schedule.findIndex((item) => item.frequency === 10);
      this.nullValue = schedule.findIndex((item) => item.frequency === '');
      if (monday !== -1) {
        this.monday = new DayScheduleData(
          schedule[monday].frequency,
          timezone
            ? this.convertToZone(timezone, schedule[monday].hoursfrom)
            : schedule[monday].hoursfrom,
          timezone
            ? this.convertToZone(timezone, schedule[monday].hoursto)
            : schedule[monday].hoursto
        );
      } else {
        if (allDay !== -1) {
          this.monday = new DayScheduleData(
            1,
            timezone
              ? this.convertToZone(timezone, schedule[allDay].hoursfrom)
              : schedule[allDay].hoursfrom,
            timezone
              ? this.convertToZone(timezone, schedule[allDay].hoursto)
              : schedule[allDay].hoursto
          );
        } else if (weekDay !== -1) {
          this.monday = new DayScheduleData(
            1,
            timezone
              ? this.convertToZone(timezone, schedule[weekDay].hoursfrom)
              : schedule[weekDay].hoursfrom,
            timezone
              ? this.convertToZone(timezone, schedule[weekDay].hoursto)
              : schedule[weekDay].hoursto
          );
        } else {
          this.monday = new DayScheduleData(1, 0, 0);
        }
      }
      if (tuesday !== -1) {
        this.tuesday = new DayScheduleData(
          schedule[tuesday].frequency,
          timezone
            ? this.convertToZone(timezone, schedule[tuesday].hoursfrom)
            : schedule[tuesday].hoursfrom,
          timezone
            ? this.convertToZone(timezone, schedule[tuesday].hoursto)
            : schedule[tuesday].hoursto
        );
      } else {
        if (allDay !== -1) {
          this.tuesday = new DayScheduleData(
            2,
            timezone
              ? this.convertToZone(timezone, schedule[allDay].hoursfrom)
              : schedule[allDay].hoursfrom,
            timezone
              ? this.convertToZone(timezone, schedule[allDay].hoursto)
              : schedule[allDay].hoursto
          );
        } else if (weekDay !== -1) {
          this.tuesday = new DayScheduleData(
            2,
            timezone
              ? this.convertToZone(timezone, schedule[weekDay].hoursfrom)
              : schedule[weekDay].hoursfrom,
            timezone
              ? this.convertToZone(timezone, schedule[weekDay].hoursto)
              : schedule[weekDay].hoursto
          );
        } else {
          this.tuesday = new DayScheduleData(2, 0, 0);
        }
      }
      if (wednesday !== -1) {
        this.wednesday = new DayScheduleData(
          schedule[wednesday].frequency,
          timezone
            ? this.convertToZone(timezone, schedule[wednesday].hoursfrom)
            : schedule[wednesday].hoursfrom,
          timezone
            ? this.convertToZone(timezone, schedule[wednesday].hoursto)
            : schedule[wednesday].hoursto
        );
      } else {
        if (allDay !== -1) {
          this.wednesday = new DayScheduleData(
            3,
            timezone
              ? this.convertToZone(timezone, schedule[allDay].hoursfrom)
              : schedule[allDay].hoursfrom,
            timezone
              ? this.convertToZone(timezone, schedule[allDay].hoursto)
              : schedule[allDay].hoursto
          );
        } else if (weekDay !== -1) {
          this.wednesday = new DayScheduleData(
            3,
            timezone
              ? this.convertToZone(timezone, schedule[weekDay].hoursfrom)
              : schedule[weekDay].hoursfrom,
            timezone
              ? this.convertToZone(timezone, schedule[weekDay].hoursto)
              : schedule[weekDay].hoursto
          );
        } else {
          this.wednesday = new DayScheduleData(3, 0, 0);
        }
      }
      if (thursday !== -1) {
        this.thursday = new DayScheduleData(
          schedule[thursday].frequency,
          timezone
            ? this.convertToZone(timezone, schedule[thursday].hoursfrom)
            : schedule[thursday].hoursfrom,
          timezone
            ? this.convertToZone(timezone, schedule[thursday].hoursto)
            : schedule[thursday].hoursto
        );
      } else {
        if (allDay !== -1) {
          this.thursday = new DayScheduleData(
            4,
            timezone
              ? this.convertToZone(timezone, schedule[allDay].hoursfrom)
              : schedule[allDay].hoursfrom,
            timezone
              ? this.convertToZone(timezone, schedule[allDay].hoursto)
              : schedule[allDay].hoursto
          );
        } else if (weekDay !== -1) {
          this.thursday = new DayScheduleData(
            4,
            timezone
              ? this.convertToZone(timezone, schedule[weekDay].hoursfrom)
              : schedule[weekDay].hoursfrom,
            timezone
              ? this.convertToZone(timezone, schedule[weekDay].hoursto)
              : schedule[weekDay].hoursto
          );
        } else {
          this.thursday = new DayScheduleData(4, 0, 0);
        }
      }
      if (friday !== -1) {
        this.friday = new DayScheduleData(
          schedule[friday].frequency,
          timezone
            ? this.convertToZone(timezone, schedule[friday].hoursfrom)
            : schedule[friday].hoursfrom,
          timezone
            ? this.convertToZone(timezone, schedule[friday].hoursto)
            : schedule[friday].hoursto
        );
      } else {
        if (allDay !== -1) {
          this.friday = new DayScheduleData(
            5,
            timezone
              ? this.convertToZone(timezone, schedule[allDay].hoursfrom)
              : schedule[allDay].hoursfrom,
            timezone
              ? this.convertToZone(timezone, schedule[allDay].hoursto)
              : schedule[allDay].hoursto
          );
        } else if (weekDay !== -1) {
          this.friday = new DayScheduleData(
            5,
            timezone
              ? this.convertToZone(timezone, schedule[weekDay].hoursfrom)
              : schedule[weekDay].hoursfrom,
            timezone
              ? this.convertToZone(timezone, schedule[weekDay].hoursto)
              : schedule[weekDay].hoursto
          );
        } else {
          this.friday = new DayScheduleData(5, 0, 0);
        }
      }
      if (saturday !== -1) {
        this.saturday = new DayScheduleData(
          schedule[saturday].frequency,
          timezone
            ? this.convertToZone(timezone, schedule[saturday].hoursfrom)
            : schedule[saturday].hoursfrom,
          timezone
            ? this.convertToZone(timezone, schedule[saturday].hoursto)
            : schedule[saturday].hoursto
        );
      } else {
        if (allDay !== -1) {
          this.saturday = new DayScheduleData(
            6,
            timezone
              ? this.convertToZone(timezone, schedule[allDay].hoursfrom)
              : schedule[allDay].hoursfrom,
            timezone
              ? this.convertToZone(timezone, schedule[allDay].hoursto)
              : schedule[allDay].hoursto
          );
        } else if (weekEnd !== -1) {
          this.saturday = new DayScheduleData(
            6,
            timezone
              ? this.convertToZone(timezone, schedule[weekEnd].hoursfrom)
              : schedule[weekEnd].hoursfrom,
            timezone
              ? this.convertToZone(timezone, schedule[weekEnd].hoursto)
              : schedule[weekEnd].hoursto
          );
        } else {
          this.saturday = new DayScheduleData(6, 0, 0);
        }
      }
      if (sunday !== -1) {
        this.sunday = new DayScheduleData(
          schedule[sunday].frequency,
          timezone
            ? this.convertToZone(timezone, schedule[sunday].hoursfrom)
            : schedule[sunday].hoursfrom,
          timezone
            ? this.convertToZone(timezone, schedule[sunday].hoursto)
            : schedule[sunday].hoursto
        );
      } else {
        if (allDay !== -1) {
          this.sunday = new DayScheduleData(
            7,
            timezone
              ? this.convertToZone(timezone, schedule[allDay].hoursfrom)
              : schedule[allDay].hoursfrom,
            timezone
              ? this.convertToZone(timezone, schedule[allDay].hoursto)
              : schedule[allDay].hoursto
          );
        } else if (weekEnd !== -1) {
          this.sunday = new DayScheduleData(
            7,
            timezone
              ? this.convertToZone(timezone, schedule[weekEnd].hoursfrom)
              : schedule[weekEnd].hoursfrom,
            timezone
              ? this.convertToZone(timezone, schedule[weekEnd].hoursto)
              : schedule[weekEnd].hoursto
          );
        } else {
          this.sunday = new DayScheduleData(7, 0, 0);
        }
      }
    }
  }
  getAllValue() {
    if (this.nullValue !== -1) {
      return {};
    } else {
      return {
        ...this.monday.getEncodeValue(),
        ...this.tuesday.getEncodeValue(),
        ...this.wednesday.getEncodeValue(),
        ...this.thursday.getEncodeValue(),
        ...this.friday.getEncodeValue(),
        ...this.saturday.getEncodeValue(),
        ...this.sunday.getEncodeValue(),
      };
    }
  }
  convertToZone(timeZone, timeData): number {
    if (timeData == -1) {
      return 0;
    } else {
      var fmt = 'MM/DD/YYYY HH:mm:ss A'; // must match the input
      let input =
        '05/30/2014 ' + this.formatted_string('00', timeData, 'l') + ':00:00';
      var newValue = momentTZ.tz(input, fmt, timeZone);

      newValue.utc();
      let t1 = newValue.format(fmt);
      return Number(newValue.format('HH'));
    }
  }

  formatted_string(pad, user_str, pad_pos) {
    if (typeof user_str === 'undefined') return pad;
    if (pad_pos == 'l') {
      return (pad + user_str).slice(-pad.length);
    } else {
      return (user_str + pad).substring(0, pad.length);
    }
  }
}
export class AddAed {
  aed_device?: String;
  floor_num?: Number;
  labels?: any[];
  users?: any[];
  placement_desc?: String;
  org_id?: number;
  public: number;
  allow_911: boolean;
  permanent: boolean;
  location: number;
  primary_manager: number;
  aed_schedule?: any;
  include_in_emergencies?: boolean;
  lost_or_stolen?: boolean;
  location_schedule: any
  constructor(ut: any, zoneString: any) {
    if (ut) {
      this.aed_device = ut.aed_serial_number;
      this.floor_num = ut.aedfloor;
      this.placement_desc = ut.description;
      this.public = ut.public;
      this.permanent = ut.permanent;
      this.location = ut.location;
      this.labels = ut.labels;
      this.primary_manager = ut.primary_manager;
      this.users = ut.users,
      this.include_in_emergencies = ut.include_in_emergencies;
      this.lost_or_stolen = ut.lost_or_stolen;
      this.allow_911 = ut.allow_911;
      this.aed_schedule = this.public === 1 ? new ScheduleData(ut.location_schedule, zoneString).getAllValue() : this.getEmptySchedule();

    }
  }
  getEmptySchedule() {
    return {"1": [0,0],"2": [0,0],"3": [0,0],"4": [0,0],"5": [0,0],"6": [0,0],"7": [0,0]}
  }
}