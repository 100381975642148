<app-av-pg>
  <app-av-pg-title>
    <app-av-pg-title-heading pg_title="Organizations"></app-av-pg-title-heading>
  </app-av-pg-title>
  <div fxLayout="column">
    <app-av-card-filter>
      <mat-form-field fxFlex="grow">
        <mat-label>Search</mat-label>
        <input type="text" matInput placeholder="Search by Account Name or Account ID" #search>
        <mat-icon (click)="applyFilter(search.value)" class="cursor-pointer" matSuffix>search</mat-icon>
      </mat-form-field>
      <mat-label class="download-text" fxFlex="10" fxFlex.lt-md="100"><a>DOWNLOAD</a></mat-label>
      <button fxFlex="25" fxFlex.lt-md="100" mat-flat-button color="primary" id="create-account" (click)="goToCreateAccount()">CREATE NEW ACCOUNT</button>
    </app-av-card-filter>
    <span class="av-card-table">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef [ngClass]="'w-20'">Account Name</th>
          <td mat-cell *matCellDef="let element">{{element.name}}</td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef [ngClass]="'w-20'">Account ID</th>
          <td mat-cell *matCellDef="let element">{{element.id}}</td>
        </ng-container>

        <ng-container matColumnDef="no_of_aeds">
          <th mat-header-cell *matHeaderCellDef [ngClass]="'w-20'"># of AEDs</th>
          <td mat-cell *matCellDef="let element">{{element.no_of_aeds}}</td>
        </ng-container>

        <ng-container matColumnDef="no_of_users">
          <th mat-header-cell *matHeaderCellDef [ngClass]="'w-20'"># of Users</th>
          <td mat-cell *matCellDef="let element">{{element.no_of_users}}</td>
        </ng-container>

        <ng-container matColumnDef="health">
          <th *matHeaderCellDef mat-header-cell [ngClass]="'w-20'">Account Health</th>
          <td *matCellDef="let row" mat-cell>
            <div class="flex" fxLayoutAlign="start center">
              <app-av-health-tag status="healthy"></app-av-health-tag>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="cursor-pointer" (click)="setAccount(row)"></tr>
      </table>
      <mat-paginator (page)="pageChanged($event)" [showFirstLastButtons]="true" [length]="pageLength" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"></mat-paginator>
    </span>
  </div>
</app-av-pg>
