import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class UILibIconService {

  iconList = [
    { 'name': 'dashboard', 'fileName': 'icons-home.svg' },
    { 'name': 'fleet-map', 'fileName': 'icons-map.svg' },
    { 'name': 'locations', 'fileName': 'icons-locations.svg' },
    { 'name': 'aeds', 'fileName': 'icons-aeds.svg' },
    { 'name': 'users', 'fileName': 'icons-user.svg' },
    { 'name': 'incidents', 'fileName': 'icons-incidents.svg' },
    { 'name': 'labels', 'fileName': 'icons-label.svg' },
    { 'name': 'store', 'fileName': 'icons-store.svg' },
    { 'name': 'accountsettings', 'fileName': 'icons-settings.svg' },
    { 'name': 'notifications', 'fileName': 'notifications.svg' },
    { 'name': 'search', 'fileName': 'search.svg' },
    { 'name': 'user-profile', 'fileName': 'icons-user-profile.svg' }
  ]

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
  }

  init() {
    this.iconList.forEach(i => {
      this.matIconRegistry.addSvgIcon(
        i.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/images/svg-icons/${i.fileName}`)
      );
    });
  }
}
