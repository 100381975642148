import { Component, OnInit } from '@angular/core';
import { LoginService } from 'app/shared/services/login.service';
import { LayoutService } from 'app/shared/services/layout.service';
import { SearchService } from 'app/shared/search/search.service';
@Component({
  selector: 'app-av-pg',
  templateUrl: './av-pg.component.html',
  styleUrls: ['./av-pg.component.scss']
})
export class AvPgComponent implements OnInit {

  public layoutConf: any;
  searchClicked: boolean;

  constructor(
    public loginService: LoginService,
    private layout: LayoutService,
    private ts: SearchService
  ) { }

  ngOnInit(): void {
    this.layoutConf = this.layout.layoutConf;
  }

  ngAfterViewInit() {
    this.ts.search.subscribe((mode: boolean) =>{
      this.searchClicked = mode;
    })
  }

}
