<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="3px">
    <mat-icon><a [class.disabled]="!enablePrevious" (click)="previousPage()">chevron_left</a></mat-icon>
    <mat-progress-spinner
      *ngIf="mode === 'indeterminate'"
        class="example-margin"
        [mode]="mode"
        [diameter]="20"
        [value]="value">
    </mat-progress-spinner>
    <span> {{currentPage}} of {{totalPages}} {{dataType}} </span>
    <mat-icon><a [class.disabled]="!enableNext" (click)="nextPage()">chevron_right</a></mat-icon>
</div>
